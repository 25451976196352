<template>
  <div>
    话单记录
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="用户id" width="120">
          <template slot-scope="scope">{{ scope.row.appId }}</template>
        </el-table-column>
        <el-table-column prop="phone" label="呼叫号码" width="120">
        </el-table-column>
        <el-table-column prop="holdTime" label="通话时长" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="orderId" label="通话id" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="callerDisplay"
          label="主叫号码"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.callerDisplay }}
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="创建时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column
          prop="address"
          label="回答内容类型"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column prop="address" label="会话内容" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="primary" @click="conversation(scope.row)"
              >会话</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="level" label="AI意向等级" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="录音" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link
              style="margin-left: 20px;"
              type="primary"
              :href="scope.row.recordUrl"
              >点击下载录音汇总</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        background
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- <div style="margin-top: 20px">
        <el-button @click="toggleSelection([tableData[1], tableData[2]])"
          >切换第二、第三行的选中状态</el-button
        >
        <el-button @click="toggleSelection()">取消选择</el-button>
      </div> -->
    </div>
    <el-dialog
      title="会话记录"
      :visible.sync="dialogFormVisible"
      :modal="false"
      center
      width="800px"
      v-if="dialogFormVisible"
    >
      <div>
        <!-- <div class="">
          <div class="">
            <img
              src="../../assets/images/user.png"
              width="40"
              height="40"
              alt=""
              class="float-r"
            />
          </div>
          <div class="">
            <div class="">客服:</div>
            <div class="" style="text-align: left;">
              喂,您好！我这边是叁友股份,专业开发智能机器人，真人语音，帮您获取意向客户，请问您这边有需要了解一下吗？
            </div>
          </div>
        </div> -->
        <!-- 左边 -->
        <div class="chat-container">
          <!-- <div style="display: flex;">
            <img
              src="../../assets/images/user.png"
              width="40"
              height="40"
              alt=""
              style="margin-right: 5px;"
            />
            <div class="message ">132123</div>
          </div> -->
          <!-- 右边 -->
          <div
            style=""
            class="yuan"
            :class="item.isRobot == 1 ? '' : 'chat-left'"
            v-for="item in ticketDetailModelList"
            :key="item.ticketDetailId"
          >
            <div
              class="message"
              :class="item.isRobot == 1 ? 'assistant-message' : 'user-message'"
            >
              <img
                src="../../assets/images/user.png"
                width="40"
                height="40"
                alt=""
                style="margin-right: 5px; position: relative; top: -10px;"
                v-show="item.isRobot == 0"
              />
              <audio :src="item.url" controls v-if="item.url"></audio>
              <div>{{ item.content }}</div>
            </div>
            <img
              src="../../assets/images/yuan1.png"
              width="40"
              height="40"
              alt=""
              style="margin-left: 5px;"
              v-show="item.isRobot == 1"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryCallTicket } from "@/api/aIMarketing";
let base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      pageNum: 1,
      pageSize: 10,
      currentPage: 1,
      total: 1,
      dialogFormVisible: false,
      ticketDetailModelList: [],
    };
  },
  created() {
    this.getQueryCallTicket();
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getQueryCallTicket() {
      const res = await queryCallTicket({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code == 200) {
        console.log(res.data.pageInfo.list);
        this.tableData = res.data.pageInfo.list;
        this.total = res.data.pageInfo.total;
        this.tableData = this.tableData.map((item) => {
          return {
            ...item,
            startTime: item.startTime
              ? this.timestampToTime(item.startTime)
              : "点呼拨打",
            endTime: item.startTime
              ? this.timestampToTime(item.endTime)
              : "点呼拨打",
          };
        });
      }
    },
    timestampToTime(timestamp) {
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      if (dateTimeRegex.test(timestamp)) {
        return timestamp;
      }
      // console.log("查看数据", timestamp);
      var date = new Date(timestamp * 1000); // 将时间戳转换为毫秒
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = ("0" + date.getHours()).slice(-2);
      var minute = ("0" + date.getMinutes()).slice(-2);
      var second = ("0" + date.getSeconds()).slice(-2);
      var formattedTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return formattedTime;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getQueryCallTicket();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getQueryCallTicket();
    },
    conversation(item) {
      console.log("查看数据", item.ticketDetailModelList);
      this.ticketDetailModelList = item.ticketDetailModelList;
      this.dialogFormVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 500px;
  padding: 10px;
  overflow-y: scroll;
}

.chat-left {
  display: flex;
  flex-direction: column;
}
.message {
  display: inline-block;
  max-width: 70%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.user-message {
  align-self: flex-start;
  background-color: #dcf8c6;
}

.assistant-message {
  align-self: flex-end;
  background-color: #e5e5ea;
}

// 右边
.yuan {
  display: flex;
  justify-content: end;
}
.user {
  display: flex;
}
</style>
